<template>
  <div>
    <el-breadcrumb class="div1" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataManage-dataAccessManage/dataAccessManage'}">任务管理</el-breadcrumb-item>
      <el-breadcrumb-item>虚拟入湖</el-breadcrumb-item>
    </el-breadcrumb>


    <div class="body">
      <div class="allDiv">
        <div class="leftDiv">
          <!--        左上角系统数据-->
          <div style="height: 60%">
            <div class="leftDivTitle"><!-- 控制左边树形区域的上方的div -->
              <el-tag style="  width: 100%;text-align: center;height: 31px">系统数据</el-tag>
            </div>
            <div class="leftDivTree">
              <el-tree
                  v-if="coalMineTree"
                  :props="coalMineTreeProps"
                  :load="coalMineTreeLoad"
                  lazy
                  @node-click="coalMineTreeNodeClick">
            <span class="custom-tree-node" slot-scope="{node, data}">
            <span> <i :class="data.icon" style="margin-right: 2px"></i>{{ node.label }}</span>
            </span>
              </el-tree>
            </div>
          </div>
          <!--        左下角数据源数据-->
          <div style="height: 35.7%;margin-top: 3.1%">
            <el-tag style="width: 100%;text-align: center;border-top:0.2px solid #000;height: 31px">数据源</el-tag>
            <div class="leftDivTree">
              <el-tree
                  v-if="dataSourceTree"
                  :data="dataSourceTreeMessage"
                  :props="dataSourceTreeProps"
                  ref="dataSourceTree"
                  node-key="tableId"
                  draggable
                  :allow-drag="dataSourceTreeAllowDrag"
                  :allow-drop="dataSourceTreeAllowDrop"
                  @node-drag-end="dataSourceTreeDragEnd">
          <span class="custom-tree-node" slot-scope="{node, data }">
            <span> <i :class="data.icon" style="margin-right: 2px"></i>{{ node.label }}</span>
          </span>
              </el-tree>
            </div>
          </div>
        </div>
        <!--        中间数据映射工作区-->
        <div class="midDiv">
          <!--          工作区顶部工具区-->
          <div class="midDivTop">
            <div class="midDivTopImg">
              <el-tooltip content="集市主题" placement="bottom-end" effect="dark">
                <i class="el-icon-price-tag" style="height: 25px;width: 25px; margin: 10px 5px 10px 10px; font-size: 20px" @click="tag()"></i>
              </el-tooltip  >
              <el-tooltip content="数据库" placement="bottom-end" effect="dark" v-if="sourceType==1">
                <i @mouseover="databaseMouseOver"
                   @mouseleave="databaseMouseLeave" id='database' @click="linkDB"
                   style="height: 25px;width: 25px; margin: 10px 5px 10px 0"
                   class="iconfont icon-shujuku1"></i>
              </el-tooltip>
              <linkDB ref="linkDB" @linkDatabase="linkDatabase($event)"></linkDB>
              <el-tooltip content="文件" placement="bottom-end" effect="dark" v-if="sourceType==2">
                <i @mouseover="fileMouseOver"
                   @mouseleave="fileMouseLeave" id='file' @click="upFile"
                   style="height: 25px;width: 25px; margin: 10px 5px 10px 0"
                   class="iconfont icon-wenjian"></i>
              </el-tooltip>
              <upFile :taskName="this.allTaskData.taskName" ref="upFile" @upFileSuccess="upFileSuccess($event)"></upFile>
              <el-tooltip content="传感器" placement="bottom-end" effect="dark" v-if="sourceType==3">
                <i @mouseover="sensorMouseOver"
                   @mouseleave="sensorMouseLeave" id='sensor' @click="linkSensor"
                   style="height: 25px;width: 25px; margin: 10px 5px 10px 0"
                   class="iconfont icon-icon-test"></i>
              </el-tooltip>
              <addSensor ref="addSensor" @addSensor="addSensor($event)"></addSensor>
              <el-tooltip content="第三方" placement="bottom-end" effect="dark" v-if="sourceType==4">
                <i @mouseover="thirdPartyMouseOver"
                   @mouseleave="thirdPartyMouseLeave" id='thirdParty' @click="linkThirdParty"
                   style="height: 25px;width: 25px; margin: 10px 5px 10px 0"
                   class="iconfont icon-icon-test"></i>
              </el-tooltip>
              <addThirdParty ref="addThirdParty" @addThirdParty="addThirdParty($event)"></addThirdParty>


              <!--              <preview :allTaskData="this.allTaskData" ref="preview"></preview>-->
              <el-tooltip content="进行虚拟入湖" placement="bottom-end" effect="dark">
                <i @mouseover="addTaskMouseOver"
                   @mouseleave="addTaskMouseLeave" id='addTask' @click="addTask()"
                   style="height: 25px;width: 25px; margin: 10px 5px 10px 0"
                   class="iconfont icon-yunhang"></i>
              </el-tooltip>
            </div>
          </div>
          <!--          工作区操作台-->
          <div class="midDivData">
            <div class="dy_datasourceDiv"  @dragover="workDragOver" @dragleave="workDragLeave">
              <el-button plain v-model="workName" style="width: 100%;font-size: 15px;color: #409EFF">{{
                  workName
                }}
              </el-button>
              <el-divider style="margin: 0px 0">
                <div style="width: 200%">
                  <el-tooltip class="item" effect="dark" content="数据源区域" placement="bottom">
                    <i class="iconfont icon-shujuku"></i>
                  </el-tooltip>
                </div>
              </el-divider>
              <div class="datasourceDiv">
                <div>
                  <div v-for="data in accessData" :key="data.id" style="display: inline-block">
                    <div style="margin: 10px">
                      <i v-if="data.data"
                         :id=data.iconId
                         @dblclick="workDataMouseDown(data.id,data)"
                         style="margin: 10px 5px 10px 15px;vertical-align: middle;"
                         :class=data.icon>
                      </i>
                      <i style="position:absolute"
                         @mouseover="deleteDataMouseOver(data.id)" @mouseleave="deleteDataMouseLeave(data.id)"
                         @click="deleteData(data.id,data)" :id=data.id v-if="data.delete"
                         class="iconfont icon-quxiao"></i>
                      <span class="demonstration"
                            style="vertical-align: middle;display: block;font-size: 20px;color: #303133;">{{
                          data.name
                        }}</span>
                    </div>
                  </div>
                </div>
                <file ref="file" :nowReflectData="this.nowReflectData" :workNode="this.workNode" :mapData="this.mapData"
                      :nowEachTaskData="this.nowEachTaskData"
                      :taskData="this.taskData" :allTaskData="this.allTaskData" :fileData="this.fileData"
                      @fileSure="fileSure($event)"></file>
                <sensor ref="sensor" :workNode="this.workNode" :nowReflectData="this.nowReflectData"
                        :mapData="this.mapData" :nowEachTaskData="this.nowEachTaskData"
                        :taskData="this.taskData" :allTaskData="this.allTaskData" :sensorData="this.sensorData" @sensorSure="sensorSure($event)"></sensor>
                <thirdParty ref="thirdParty" :workNode="this.workNode" :nowReflectData="this.nowReflectData"
                            :mapData="this.mapData" :nowEachTaskData="this.nowEachTaskData"
                            :taskData="this.taskData" :allTaskData="this.allTaskData" :thirdPartyData="this.thirdPartyData" @thirdPartySure="thirdPartySure($event)"></thirdParty>
                <selfForm ref="selfForm" :workNode="this.workNode" :mapDataForm="this.mapDataForm"
                          :nowReflectData="this.nowReflectData" :nowEachTaskData="this.nowEachTaskData"
                          :taskData="this.taskData" :allTaskData="this.allTaskData" :formData="this.formData"
                          :activeMap="this.activeMap" @formSure="formSure"></selfForm>
                <opc ref="opc" :workNode="this.workNode" :nowReflectData="this.nowReflectData"
                     :mapData="this.mapData" :nowEachTaskData="this.nowEachTaskData"
                     :taskData="this.taskData" :allTaskData="this.allTaskData" :opcData="this.opcData" @opcSure="opcSure($event)"></opc>
              </div>
            </div>
          </div>
        </div>

        <!--        右侧映射预览区-->
        <div class="rightDiv">
          <!--        右上角系统数据-->
          <div style="height: 60%;">
            <el-tag style="width: 100%;text-align: center;border-bottom: 0.2px solid #000000; height: 31px">工作区映射预览</el-tag>
            <el-table
                :data="mapData"
                border
                style="width: 100%;height: 93.5%;overflow: scroll;">
              <el-table-column
                  prop="deviceData"
                  label="数据项名称">
              </el-table-column>
              <el-table-column
                  prop="tableName"
                  label="数据源名称">
              </el-table-column>
              <el-table-column
                  prop="tableData"
                  label="数据源字段">
              </el-table-column>
            </el-table>
          </div>
          <!--        右下角数据源数据-->
          <div style="height: 40%;">
            <el-tag style="width: 100%;text-align: center;border-bottom: 0.2px solid #000000;border-top: 0.2px solid #000000; height: 31px">工作区映射预览</el-tag>
            <el-table
                :data="workDataList"
                border
                style="width: 100%;height: 90.5%;border-bottom: 0.2px solid #000000;overflow: scroll; ">
              <el-table-column
                  prop="workName"
                  label="工作区列表">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!--    弹窗-->
    <div class="pop">
      <choose-tag v-if="tagDialog" ref="chooseTag"></choose-tag>
      <!--      映射表-->
      <el-dialog title="数据库类型映射选择表"
                 :close-on-click-modal="false"
                 :visible.sync="tableDialog"
                 width="50%"
                 append-to-body>
        <div style="margin-top: 10px;margin-left: 25px;">
          <el-row :gutter="70">
            <el-col :span="11">
              <span style="width: 10%">附属设备名称:</span>
              <el-input v-model="deviceName" style="margin-left: 10px; width: 70%" disabled></el-input>
            </el-col>
            <el-col :span="11">
              <span style="width: 10%">表名称:</span>
              <el-input v-model="tableName" style="margin-left: 10px; width: 70%" disabled></el-input>
            </el-col>
          </el-row>
          <div style="margin-top: 20px">
            <div style="margin-left: 1%;margin-top: 1%;">
              <div>
                <el-row :gutter="70">
                  <el-col :span="11">
                    <div>
                      <!--                    <el-input-->
                      <!--                        prefix-icon="el-icon-search"-->
                      <!--                        @keyup.native="dFSearch"-->
                      <!--                        ref="dFieldSearch"-->
                      <!--                        v-model="deviceFieldSearch"-->
                      <!--                        size="mini"-->
                      <!--                        style="width: 100%;margin-top: 1%;margin-bottom: 1%;"-->
                      <!--                        placeholder="输入关键字搜索"/>-->
                      <el-table
                          ref="selectDeviceField1"
                          :data="deviceFieldData"
                          tooltip-effect="dark"
                          height=250
                          border
                          style="width: 100%"
                          :header-cell-class-name="cellClass"
                          @select="deviceFieldSelection"
                          @cell-click="cellClick">
                        <el-table-column
                            type="selection"
                            min-width="30%"
                            :selectable="selectDeviceStatus">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="数据项列表"
                            min-width="70%">
                          <!--                          <template slot-scope="scope">-->
                          <!--                            <el-input v-model="scope.row.name" v-if="scope.row.seen"-->
                          <!--                                      @blur="loseFocus(scope.$index, scope.row)"></el-input>-->
                          <!--                            <span style="margin-left: 10px" v-else>{{ scope.row.name }}</span>-->
                          <!--                          </template>-->
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            label="数据项类型"
                            min-width="70%">
                        </el-table-column>
                      </el-table>
                      <!--                    <el-button @click="deviceAdd" size="mini" type="primary"-->
                      <!--                               style="width: 100%;margin-top: 1%;margin-bottom: 1%;" round>添 加-->
                      <!--                    </el-button>-->
                    </div>
                  </el-col>
                  <el-col :span="11">
                    <div>
                      <!--                    <el-input-->
                      <!--                        prefix-icon="el-icon-search"-->
                      <!--                        @keyup.native="tFSearch"-->
                      <!--                        ref="tFieldSearch"-->
                      <!--                        v-model="tableFieldSearch"-->
                      <!--                        size="mini"-->
                      <!--                        style="width: 100%;margin-top: 1%;margin-bottom: 1%;border-radius:10px;"-->
                      <!--                        placeholder="输入关键字搜索"/>-->
                      <el-table
                          ref="selectTableField"
                          :data="tableFieldData"
                          tooltip-effect="dark"
                          height=250
                          border
                          style="width: 100%"
                          :header-cell-class-name="cellClass"
                          @select="tableFieldSelection">
                        <el-table-column
                            type="selection"
                            min-width="30%"
                            :selectable="selectTableStatus">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="数据源字段"
                            min-width="70%">
                        </el-table-column>
                        <el-table-column
                            prop="type"
                            label="数据项类型"
                            min-width="70%">
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <el-row :gutter="50">
              <el-col :span="17">
                <div>
                  <el-table
                      :data="selectData"
                      height="250"
                      border
                      style="width: 100%">
                    <el-table-column
                        prop="selectDeviceField"
                        label="已选择设备字段"
                        min-width="40%">
                    </el-table-column>
                    <el-table-column
                        prop="selectTableField"
                        label="已选择表字段"
                        min-width="40%">
                    </el-table-column>
                    <el-table-column min-width="40%" label="操作">
                      <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDeleteTable(scope.$index, scope.row)">删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-col>
              <el-col :span="7">
                <div>
                  <el-button type="primary" @click="sureSelect">确定该映射</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
       <el-button @click="tableDialog = false">取 消</el-button>
       <el-button type="primary" @click="tableSure">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import chooseTag from "./chooseTag" // 打标签组件
import linkDB from "./linkDB"
import codeTemplate from "./codeTemplate"
import addCoal from "./addCoal"
import addSensor from "./addSensor"
import upFile from "./upFile"
// import preview from "./preview"
import file from "./file"
import sensor from "./sensor"
import addThirdParty from "./addThirdParty"
import thirdParty from "./thirdParty"
import makeForm from "./makeForm"
import selfForm from "./selfForm"
import opc from "./opc"

export default {
  name: "addDataAccessTaskNew.vue",
  data() {
    return {
      // // 任务基本数据规则
      // dataRules: {
      //   taskName: [
      //     { required: true, message: '请输入任务名称', trigger: 'blur' }
      //   ],
      //   remark: [
      //     { required: true, message: '请输入任务描述', trigger: 'blur' }
      //   ]
      // },

      // 接入源类型
      sourceType: 1,

      currOperator: '',//操作员的id
      currOperatorName: '',//操作员的名称
      tagDialog: false,  // tagDialog: false,

      dragFlg: 0, // 数据源节点拖拽标志

      tableDialog: false, // 数据库映射表弹窗标志


      coalMineTree: true, // 树形数据刷新标志
      dataSourceTree: true, // 树形数据刷新标志


      workName: "无", //当前工作区的名字
      workNode: '', //工作区的节点
      workData: [], //所有工作区的信息（工作节点、工作区内的数据源）(包含多个数据源)
      workId: 0, //工作区id

      tableFieldData: [], // 映射表数据源字段数据
      deviceFieldData: [], // 映射表数据项字段数据

      selectData: [], // 映射表下侧已选择的映射
      selectTableField: {},//当前选择的表字段
      selectTableSecondField: {},//下一个要选择的表字段
      selectDeviceField: {},//当前选择的设备字段
      selectDeviceSecondField: {},//下一个要选择的设备字段

      accessData: [], // 接入数据,工作区使用的数据
      tableData: [], //当前工作区内的表
      tableName: '',//表名字
      tableId: 0,//表id
      nowTableId: 0,//当前选择的表id
      nowFileId: 0,//当前选择的文件id
      nowSensorId:0,//当前选择的传感器id
      nowThirdPartyId:0,//当前选择的第三方id
      nowOpcId:0,//当前选择的opcid
      deviceName: '',//设备名称
      fileData: [],//当前工作区内的文件
      fileId: 0,//当前工作区内文件
      sensorData: [],//当前工作区内的传感器
      sensorId: 0,//传感器id
      thirdPartyData: [],//当前工作区内的第三方
      thirdPartyId: 0,//第三方id
      formData: [],//当前工作区内的第三方
      formId: 0,//
      opcData: [],//当前工作区内的opc
      opcId: 0,//opc id


      //树形的数据源
      dataSourceTreeMessage: [],//数据源树形数据
      coalMineTreeProps: { //煤矿树形属性
        children: 'children',
        label: 'name',
        isLeaf: 'leaf',
      },
      dataSourceTreeProps: { //数据源树形属性
        children: 'children',
        label: 'name',
      },
      activeMap: "tableActiveMap",//当前显示的映射区域
      //在右侧显示已经映射的字段
      mapData: [],//数据库映射关系列表
      workDataList: [],//工作区信息列表
      mapDataSensor: [],//传感器映射关系列表
      mapDataFile: [],//文件映射关系列表
      mapDataThirdParty: [],//第三方映射关系列表
      mapDataForm: [],

      //任务数据
      nowDB: '',//当前数据库
      // nowTable: '',
      nowDBType: '',//当前数据库类型

      nowEachTaskData: {},//当前的一个任务数据
      nowReflectData:  {},//当前映射数据
      nowOneReflectDB: {},//当前一个DB的映射
      nowOneReflectDBTable: {},//当前一个表的映射
      oneReflectDBTable_temp: {//表映射的数据结构
        tableName: '',
        sourceField: '',
        fieldName: '',
        fieldId: '',
        reflectPath: ''
      },
      oneReflectDB_temp: {//库映射的数据结构
        DBName: '',
        table: []
      },
      //所有数据源映射
      reflectData_temp: { //所有数据源映射的数据结构
        DB: [],
        Sensor: [],
        File: [],
        ThirdParty: [],
        Form: [],
        Opc:[],
      },

      each_taskData_temp: { //一个任务映射关系的数据结构
        subEquipmentId: '',
        reflectData: {},
        name: '',
        id: 0,
      },
      taskData: [], //一个任务的数据列表
      allTaskData: {  //全部任务的数据结构
        taskName: '',
        remark: '',
        type:'',
        currOperator: '',
        update:0,
        accessSource: '',
        taskData: [],
        linkId: 0,
        flag: 0,
        taskId: 0,
      },//整个任务对象


      /**
       *路由跳转的参数
       */
      routeParamsTaskName: '', //任务名称
      routeParamsRemark: '', //标记
      routeParamsType:'', //数据源类型
      routeParamsCurrOperator: '',//操作员
      routeParamsUpdateFlag: 0,
      // flag:0,//接入数据类型标识
      //任务id
      taskId: 0,
      subEquipmentIdByAtlas: undefined,

      //修改时的数据
      updateData: []
    };
  },

  methods: {
    // 左上角数据源加载函数
    coalMineTreeLoad(node, resolve) {
      //第一层级：展示项目信息
      if(node.level === 0){
        let data = []
        this.postRequest("/levelManage/projectattributes/getAllProject").then(response => {
          console.log("后端返回的项目信息："+JSON.stringify(response.data))
          //{"msg":"success","code":0,"data":[{"name":"project1","disabled":"2","id":3},{"name":"qwer","disabled":"0","id":4}]}
          for(let i = 0; i < response.data.data.length; i++){
            response.data.data[i].icon = 'iconfont icon-xuanze2'
            console.log(response.data.data[i].name+"  "+response.data.data[i].disabled)
            if(response.data.data[i].disabled == 0){
              //isdisabled为1代表未定义层级关系
              response.data.data[i].isdisabled =1
            }
            else {
              response.data.data[i].isdisabled = 0
            }
            response.data.data[i].treeNodeDeep = 1
            data.push(response.data.data[i])
          }
          return resolve(data);
        })
      }

      //第二层级：只展示非叶子节点
      //注意：叶子节点用subEquipment标志，为了复用以前的代码
      //修改功能先搁置
      if(node.level === 1) {
        console.log("node.data.isdisabled:"+node.data.isdisabled)
        if (node.data.isdisabled == 1) return resolve([])
        else {
          //先渲染非叶子节点
          let data = []
          this.postRequest("/universaltable/getRootLevel?id=" + node.data.id).then(response => {
            console.log("第二层非叶子节点信息：" + JSON.stringify(response.data))
            for (let j = 0; j < response.data.data.length; j++) {
              //除了第一层，其他节点均可选，都要加上叶子节点标志
              response.data.data[j].icon = 'iconfont icon-xuanze2'
              response.data.data[j].levelId = response.data.levelId
              response.data.data[i].treeNodeDeep = 2
              response.data.data[j].flag = 'subEquipment'
              response.data.data[j].leaf = true
              data.push(response.data.data[j])
            }
            console.log("testPoint1 data:"+data)
            return resolve(data);
          })
              // .then(result => {
                // //再渲染第二层级的叶子节点
                // this.postRequest("/universaltable/getRootLeafLevel?id=" + node.data.id).then(response => {
                //   console.log("第二层叶子节点信息：" + JSON.stringify(response.data))
                //   for (let j = 0; j < response.data.data.length; j++) {
                //     response.data.data[j].icon = 'iconfont icon-xuanze2'
                //     //叶子节点标志
                //     response.data.data[j].flag = 'subEquipment'
                //     response.data.data[j].leaf = true
                //     response.data.data[j].levelId = response.data.levelId
                //     response.data.data[i].treeNodeDeep = 2
                //     data.push(response.data.data[j])
                //   }
                //   //叶子节点
                //   if (this.routeParamsUpdateFlag === 1) {
                //     for (let m = 0; m < response.data.length; m++) {
                //       for (let key in this.updateData) {
                //         if (response.data[m].subEquipmentId === parseInt(key)) {
                //           response.data[m].icon = 'iconfont icon-yixuanze1'
                //         }
                //       }
                //     }
                //   }
                //   return resolve(data);
                // }).then(error => {
                //   //这是点击修改时才会走的流程，用于读取数据
                //   //这里开始不懂，当点击到最后一层时，具体要做什么？
                //   //routeParamsUpdateFlag作用是什么？
                //
                //   //这里是修改时才走的流程，只有mysql才有此功能，最后再改
                //   if (this.routeParamsUpdateFlag === 1) {
                //     //this.updateData什么时候有的，保存什么数据？
                //     for (let key in this.updateData) {
                //       for (let m = 0; m < node.childNodes.length; m++) {
                //         if (node.childNodes[m].data.subEquipmentId === parseInt(key)) {
                //           let workData = {
                //             node: node.childNodes[m],
                //             data: [],
                //             workName: node.childNodes[m].parent.parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.data.name + " >>> " + node.childNodes[m].parent.data.name + " >>> " + node.childNodes[m].data.name
                //           }
                //           let taskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
                //           taskData.subEquipmentId = parseInt(key)
                //           taskData.id = this.taskId
                //           this.taskId++
                //           taskData.name = node.childNodes[m].data.name
                //           taskData.reflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
                //           //一个映射的数据结构
                //           let DBTemp = JSON.parse(JSON.stringify(this.oneReflectDB_temp))
                //           //遍历数据库
                //           for (let key2 in this.updateData[key]) {
                //             DBTemp.DBName = key2
                //             //遍历表
                //             for (let key3 in this.updateData[key][key2]) {
                //               let tableName = key3.split("___")
                //               console.log("tableNode", tableName[1])
                //               console.log("tableNode", this.dataSourceTreeMessage)
                //               let tableNode = this.$refs.dataSourceTree.getNode(tableName[1])
                //               console.log("tableNode", tableNode)
                //               this.allTaskData.linkId = tableNode.parent.data.id
                //               let tableData = {
                //                 subEquipmentId: parseInt(key),
                //                 id: this.tableId,
                //                 iconId: 'icon' + this.tableId,
                //                 icon: "iconfont icon-biaodanmoshi3",
                //                 data: true,
                //                 node: tableNode,
                //                 delete: true,
                //                 name: tableNode.parent.data.name + "." + tableNode.data.name,
                //                 tableName: tableNode.data.name,
                //                 tableDB: tableNode.parent.data.name,
                //                 map: [],
                //               }
                //               //映射 库-表-字段
                //               for (let key4 in this.updateData[key][key2][key3]) {
                //                 let fieldName = key4.split("___")
                //                 let mapTemp = {
                //                   fieldId: parseInt(fieldName[1]),
                //                   selectDeviceField: fieldName[0],
                //                   selectTableField: this.updateData[key][key2][key3][key4],
                //                   tableName: tableName[0],
                //                 }
                //                 //tabledata--一个表的数据
                //                 tableData.map.push(mapTemp)
                //               }
                //               //s** edit
                //               //
                //               this.nowDB = tableNode.parent.data.name
                //               // this.nowTable = node.data.name
                //               //添加到tableData中
                //               this.tableData.push(tableData)
                //               // this.accessData = this.tableData
                //               // this.imageTableData.push(imageTableData)
                //               this.tableId = this.tableId + 1
                //               this.dragFlg = 0
                //               tableNode.data.darg = 0
                //               this.$set(tableNode.data, 'icon', 'iconfont icon-biaodanmoshi1')
                //               for (let key4 in this.updateData[key][key2][key3]) {
                //                 let tableTemp = JSON.parse(JSON.stringify(this.oneReflectDBTable_temp))
                //                 tableTemp.tableName = tableName[0]
                //                 let fieldName = key4.split("___")
                //                 tableTemp.sourceField = this.updateData[key][key2][key3][key4]
                //                 tableTemp.fieldName = fieldName[0]
                //                 tableTemp.fieldId = parseInt(fieldName[1])
                //                 DBTemp.table.push(tableTemp)
                //               }
                //             }
                //             taskData.reflectData.DB.push(DBTemp)
                //           }
                //           this.taskData.push(taskData)
                //           workData.data = this.tableData
                //           this.tableData = []
                //           this.workData.push(workData)
                //           console.log("this.workData", this.workData)
                //           console.log("this.taskData", this.taskData)
                //         }
                //       }
                //     }
                //   }
                // })
              // }
          // )
        }
      }
      //第三层级及往下使用相同逻辑渲染
      if(node.level > 1){
        for(let i = 2;i < 50; i++){
          if(node.level === i) {
            //先渲染非叶子节点
            let data = []
            this.postRequest("/universaltable/getSonLevel?tableId=" + node.data.levelId + "&id=" + node.data.id).then(response => {
              console.log("第三层非叶子节点信息：" + JSON.stringify(response.data))
              for (let j = 0; j < response.data.data.length; j++) {
                response.data.data[j].icon = 'iconfont icon-xuanze2'
                //叶子节点标志
                response.data.data[j].flag = 'subEquipment'
                response.data.data[j].leaf = true
                response.data.data[j].levelId = response.data.levelId
                response.data.data[i].treeNodeDeep = i + 1
                data.push(response.data.data[j])
              }
              // return resolve(data);
              // console.log("testPoint1 data:"+data)
            })
          }
          //       .then( result => {
          //         //再渲染第二层级的叶子节点
          //         this.postRequest("/universaltable/getNotLeafItemByParent?tableId="+node.data.levelId+"&id="+ node.data.id).then(response => {
          //           console.log("第二层叶子节点信息："+JSON.stringify(response.data))
          //           for (let j = 0; j < response.data.data.length; j++) {
          //             response.data.data[j].icon = 'iconfont icon-xuanze2'
          //             //叶子节点标志
          //             response.data.data[j].flag = 'subEquipment'
          //             response.data.data[j].leaf = true
          //             response.data.data[j].levelId = response.data.levelId
          //             response.data.data[i].treeNodeDeep = i+1
          //             data.push(response.data.data[j])
          //           }
          //           //叶子节点
          //           if (this.routeParamsUpdateFlag === 1) {
          //             for (let m = 0; m < response.data.length; m++) {
          //               for (let key in this.updateData) {
          //                 if (response.data[m].subEquipmentId === parseInt(key)) {
          //                   response.data[m].icon = 'iconfont icon-yixuanze1'
          //                 }
          //               }
          //             }
          //           }
          //           return resolve(data);
          //         })
          //       }
          //   ).then(error => {
          //     //这是点击修改时才会走的流程，用于读取数据
          //     //这里开始不懂，当点击到最后一层时，具体要做什么？
          //     //routeParamsUpdateFlag作用是什么？
          //     if (this.routeParamsUpdateFlag === 1) {
          //       //this.updateData什么时候有的，保存什么数据？
          //       for (let key in this.updateData) {
          //         for (let m = 0; m < node.childNodes.length; m++) {
          //           if (node.childNodes[m].data.subEquipmentId === parseInt(key)) {
          //             let workData = {
          //               node: node.childNodes[m],
          //               data: [],
          //               workName:node.childNodes[m].parent.parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.data.name + " >>> " + node.childNodes[m].parent.data.name + " >>> " + node.childNodes[m].data.name
          //             }
          //             let taskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
          //             taskData.subEquipmentId = parseInt(key)
          //             taskData.id = this.taskId
          //             this.taskId++
          //             taskData.name = node.childNodes[m].data.name
          //             taskData.reflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
          //             //一个映射的数据结构
          //             let DBTemp = JSON.parse(JSON.stringify(this.oneReflectDB_temp))
          //             //遍历数据库
          //             for (let key2 in this.updateData[key]) {
          //               DBTemp.DBName = key2
          //               //遍历表
          //               for (let key3 in this.updateData[key][key2]) {
          //                 let tableName = key3.split("___")
          //                 console.log("tableNode",tableName[1])
          //                 console.log("tableNode",this.dataSourceTreeMessage)
          //                 let tableNode = this.$refs.dataSourceTree.getNode(tableName[1])
          //                 console.log("tableNode",tableNode)
          //                 this.allTaskData.linkId = tableNode.parent.data.id
          //                 let tableData = {
          //                   subEquipmentId: parseInt(key),
          //                   id: this.tableId,
          //                   iconId: 'icon' + this.tableId,
          //                   icon: "iconfont icon-biaodanmoshi3",
          //                   data: true,
          //                   node: tableNode,
          //                   delete: true,
          //                   name: tableNode.parent.data.name + "." + tableNode.data.name,
          //                   tableName: tableNode.data.name,
          //                   tableDB: tableNode.parent.data.name,
          //                   map: [],
          //                 }
          //                 //映射 库-表-字段
          //                 for (let key4 in this.updateData[key][key2][key3]) {
          //                   let fieldName = key4.split("___")
          //                   let mapTemp = {
          //                     fieldId: parseInt(fieldName[1]),
          //                     selectDeviceField: fieldName[0],
          //                     selectTableField: this.updateData[key][key2][key3][key4],
          //                     tableName: tableName[0],
          //                   }
          //                   //tabledata--一个表的数据
          //                   tableData.map.push(mapTemp)
          //                 }
          //                 //s** edit
          //                 //
          //                 this.nowDB = tableNode.parent.data.name
          //                 // this.nowTable = node.data.name
          //                 //添加到tableData中
          //                 this.tableData.push(tableData)
          //                 // this.accessData = this.tableData
          //                 // this.imageTableData.push(imageTableData)
          //                 this.tableId = this.tableId + 1
          //                 this.dragFlg = 0
          //                 tableNode.data.darg = 0
          //                 this.$set(tableNode.data, 'icon', 'iconfont icon-biaodanmoshi1')
          //                 for (let key4 in this.updateData[key][key2][key3]) {
          //                   let tableTemp = JSON.parse(JSON.stringify(this.oneReflectDBTable_temp))
          //                   tableTemp.tableName = tableName[0]
          //                   let fieldName = key4.split("___")
          //                   tableTemp.sourceField = this.updateData[key][key2][key3][key4]
          //                   tableTemp.fieldName = fieldName[0]
          //                   tableTemp.fieldId = parseInt(fieldName[1])
          //                   DBTemp.table.push(tableTemp)
          //                 }
          //               }
          //               taskData.reflectData.DB.push(DBTemp)
          //             }
          //             this.taskData.push(taskData)
          //             workData.data = this.tableData
          //             this.tableData = []
          //             this.workData.push(workData)
          //             console.log("this.workData", this.workData)
          //             console.log("this.taskData", this.taskData)
          //           }
          //         }
          //       }
          //     }
          //   })
          // }
        }
      }

      // if (node.level === 0) {
      //   let data = []
      //   this.postRequest("/addDataAccessTask/taskCoalOptionsByUid?uid=" + this.currOperator).then(response => {
      //     for (let i = 0; i < response.data.length; i++) {
      //       response.data[i].icon = 'iconfont icon-xuanze2'
      //       data.push(response.data[i])
      //     }
      //     return resolve(data);
      //   })
      //   // setTimeout(() => {
      //   //   return resolve(data);
      //   // }, 2000)
      // }
      // if (node.level === 1) {
      //   //node.data.name,node.data.cid这些信息是在node.level===0时push进去的，数据库中存在
      //   let coal = {
      //     coal: node.data.name,
      //     cid: node.data.cid
      //   }
      //   let data = []
      //   this.postRequest("/addDataAccessTask/attributeCoal", coal).then(response => {
      //     // console.log(response.data)
      //     for (let j = 0; j < response.data.length; j++) {
      //       response.data[j].icon = 'iconfont icon-xuanze2'
      //       data.push(response.data[j])
      //     }
      //     return resolve(data);
      //   })
      //   // setTimeout(() => {
      //   //   return resolve(data);
      //   // }, 2000)
      // }
      // if (node.level === 2) {
      //   let system = {
      //     coal: node.parent.data.coal,
      //     cid: node.parent.data.cid,
      //     system: node.data.name,
      //     systemId: node.data.systemId
      //   }
      //   let data = []
      //   this.postRequest("/addDataAccessTask/attributeSystem", system).then(response => {
      //     for (let k = 0; k < response.data.length; k++) {
      //       response.data[k].icon = 'iconfont icon-xuanze2'
      //       data.push(response.data[k])
      //     }
      //     return resolve(data);
      //   })
      //   // setTimeout(() => {
      //   //   return resolve(data);
      //   // }, 1000)
      // }
      // if (node.level === 3) {
      //   let subsystem = {
      //     coal: node.parent.parent.data.coal,
      //     cid: node.parent.parent.data.cid,
      //     system: node.parent.data.system,
      //     systemId: node.parent.data.systemId,
      //     subsystem: node.data.name,
      //     subsystemId: node.data.subsystemId
      //   }
      //   let data = []
      //   this.postRequest("/addDataAccessTask/attributeSubSystem", subsystem).then(response => {
      //     for (let l = 0; l < response.data.length; l++) {
      //       response.data[l].icon = 'iconfont icon-xuanze2'
      //       data.push(response.data[l])
      //     }
      //     return resolve(data);
      //   })
      //   // setTimeout(() => {
      //   //   return resolve(data);
      //   // }, 1000)
      // }
      // if (node.level === 4) {
      //   let device = {
      //     coal: node.parent.parent.parent.data.coal,
      //     cid: node.parent.parent.parent.data.cid,
      //     system: node.parent.parent.data.system,
      //     systemId: node.parent.parent.data.systemId,
      //     subsystem: node.parent.data.subsystem,
      //     subsystemId: node.parent.data.subsystemId,
      //     device: node.data.name,
      //     equipmentId: node.data.equipmentId
      //   }
      //   let data = []
      //   this.postRequest("/addDataAccessTask/attributeDevice", device).then(response => {
      //     for (let m = 0; m < response.data.length; m++) {
      //       response.data[m].icon = 'iconfont icon-xuanze2'
      //       response.data[m].flag = 'subEquipment'
      //       response.data[m].leaf = true
      //       data.push(response.data[m])
      //     }
      //     if (this.routeParamsUpdateFlag === 1) {
      //       for (let m = 0; m < response.data.length; m++) {
      //         for (let key in this.updateData) {
      //           if (response.data[m].subEquipmentId === parseInt(key)) {
      //             response.data[m].icon = 'iconfont icon-yixuanze1'
      //           }
      //         }
      //       }
      //     }
      //     return resolve(data);
      //   }).then(error => {
      //     //这是点击修改时才会走的流程，用于读取数据
      //     //这里开始不懂，当点击到最后一层时，具体要做什么？
      //     //routeParamsUpdateFlag作用是什么？
      //     if (this.routeParamsUpdateFlag === 1) {
      //       //this.updateData什么时候有的，保存什么数据？
      //       for (let key in this.updateData) {
      //         for (let m = 0; m < node.childNodes.length; m++) {
      //           if (node.childNodes[m].data.subEquipmentId === parseInt(key)) {
      //             let workData = {
      //               node: node.childNodes[m],
      //               data: [],
      //               workName: node.childNodes[m].parent.parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.parent.data.name + " >>> " + node.childNodes[m].parent.parent.data.name + " >>> " + node.childNodes[m].parent.data.name + " >>> " + node.childNodes[m].data.name
      //             }
      //             let taskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
      //             taskData.subEquipmentId = parseInt(key)
      //             taskData.id = this.taskId
      //             this.taskId++
      //             taskData.name = node.childNodes[m].data.name
      //             taskData.reflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
      //             //一个映射的数据结构
      //             let DBTemp = JSON.parse(JSON.stringify(this.oneReflectDB_temp))
      //             //遍历数据库
      //             for (let key2 in this.updateData[key]) {
      //               DBTemp.DBName = key2
      //               //遍历表
      //               for (let key3 in this.updateData[key][key2]) {
      //                 let tableName = key3.split("___")
      //                 console.log("tableNode", tableName[1])
      //                 console.log("tableNode", this.dataSourceTreeMessage)
      //                 let tableNode = this.$refs.dataSourceTree.getNode(tableName[1])
      //                 console.log("tableNode", tableNode)
      //                 this.allTaskData.linkId = tableNode.parent.data.id
      //                 let tableData = {
      //                   subEquipmentId: parseInt(key),
      //                   id: this.tableId,
      //                   iconId: 'icon' + this.tableId,
      //                   icon: "iconfont icon-biaodanmoshi3",
      //                   data: true,
      //                   node: tableNode,
      //                   delete: true,
      //                   name: tableNode.parent.data.name + "." + tableNode.data.name,
      //                   tableName: tableNode.data.name,
      //                   tableDB: tableNode.parent.data.name,
      //                   map: [],
      //                 }
      //                 //映射 库-表-字段
      //                 for (let key4 in this.updateData[key][key2][key3]) {
      //                   let fieldName = key4.split("___")
      //                   let mapTemp = {
      //                     fieldId: parseInt(fieldName[1]),
      //                     selectDeviceField: fieldName[0],
      //                     selectTableField: this.updateData[key][key2][key3][key4],
      //                     tableName: tableName[0],
      //                   }
      //                   //tabledata--一个表的数据
      //                   tableData.map.push(mapTemp)
      //                 }
      //                 //s** edit
      //                 //
      //                 this.nowDB = tableNode.parent.data.name
      //                 // this.nowTable = node.data.name
      //                 //添加到tableData中
      //                 this.tableData.push(tableData)
      //                 // this.accessData = this.tableData
      //                 // this.imageTableData.push(imageTableData)
      //                 this.tableId = this.tableId + 1
      //                 this.dragFlg = 0
      //                 tableNode.data.darg = 0
      //                 this.$set(tableNode.data, 'icon', 'iconfont icon-biaodanmoshi1')
      //                 for (let key4 in this.updateData[key][key2][key3]) {
      //                   let tableTemp = JSON.parse(JSON.stringify(this.oneReflectDBTable_temp))
      //                   tableTemp.tableName = tableName[0]
      //                   let fieldName = key4.split("___")
      //                   tableTemp.sourceField = this.updateData[key][key2][key3][key4]
      //                   tableTemp.fieldName = fieldName[0]
      //                   tableTemp.fieldId = parseInt(fieldName[1])
      //                   DBTemp.table.push(tableTemp)
      //                 }
      //               }
      //               taskData.reflectData.DB.push(DBTemp)
      //             }
      //             this.taskData.push(taskData)
      //             workData.data = this.tableData
      //             this.tableData = []
      //             this.workData.push(workData)
      //             console.log("this.workData", this.workData)
      //             console.log("this.taskData", this.taskData)
      //           }
      //         }
      //       }
      //     }
      //   })
      //   // setTimeout(() => {
      //   //   return resolve(data);
      //   // }, 1000)
      // }
      // if (node.level >= 5) {
      //   return resolve([])
      // }
    },
    coalMineTreeNodeClick(data, node) {
      console.log("点击左上角的node: ", node)
      //点击节点为叶子节点
      if (node.data.flag === 'subEquipment') {
        let flag = 0
        this.mapData = []
        this.mapDataSensor = []
        //切换数据源的图标类型
        let icon='';
        let icon1='';
        if( this.allTaskData.accessSource==='传感器'){
          icon='iconfont icon-icon-test'
          icon1='iconfont icon-icon-test1'
        }else if(this.allTaskData.accessSource === 'mysql' || this.allTaskData.accessSource === 'sql server' || this.allTaskData.accessSource === 'oracle' || this.allTaskData.accessSource === 'influxDB'){
          icon='iconfont icon-biaodanmoshi'
          icon1='iconfont icon-biaodanmoshi1'
        }else if(this.allTaskData.accessSource==='txt'){
          icon='iconfont icon-txtwenjian'
          icon1='iconfont icon-txtwenjian1'
        }else if (this.allTaskData.accessSource === 'word') {
          icon='iconfont icon-Word'
          icon1='iconfont icon-Word1'
        } else if (this.allTaskData.accessSource === 'excel') {
          icon='iconfont icon-Excel'
          icon1='iconfont icon-Excel1'
        }else if(this.allTaskData.accessSource==='第三方'){
          icon='iconfont icon-icon-test'
          icon1='iconfont icon-icon-test1'
        }else if(this.allTaskData.accessSource==='opc'){
          icon='iconfont icon-icon-test'
          icon1='iconfont icon-icon-test1'
        }
        //这个taskData的数据是什么时候在哪里存进去的？
        console.log("this.taskData: ", this.taskData)
        //taskData里面能存多个
        for (let i = 0; i < this.taskData.length; i++) {
          //这个subEquipmentId唯一确定该节点，现改为levelId和Id一起确定一个叶子节点(node.data.levelId和node.data.Id唯一确定)
          //这里的nodeId保存该节点原本的id,因为在taskData里的id保存着workId,不能用id项
          if(node.data.levelId === this.taskData[i].levelId && node.data.id === this.taskData[i].nodeId){
            flag = 1;
            this.nowEachTaskData = this.taskData[i]
            //切换显示的映射信息
            if (this.allTaskData.accessSource !== '') {
              //如果映射信息不为空，则恢复映射信息
              if (JSON.stringify(this.nowEachTaskData.reflectData) !== "{}") {

                for (let i = 0; i < this.nowEachTaskData.reflectData.DB.length; i++) {
                  let DB = this.nowEachTaskData.reflectData.DB[i]
                  for (let j = 0; j < DB.table.length; j++) {
                    let table = DB.table[j]
                    let data = {
                      tableData: table.sourceField,
                      deviceData: table.fieldName,
                      tableName: table.tableName,
                      fieldId: table.fieldId,
                    }
                    this.mapData.push(data)
                    console.log(this.mapData)
                  }
                }
                for (let i = 0; i < this.nowEachTaskData.reflectData.Sensor.length; i++) {
                  let data = {
                    cgqData: this.nowEachTaskData.reflectData.Sensor[i].cgqInfoSearch,
                    deviceData: this.nowEachTaskData.reflectData.Sensor[i].fieldName,
                  }
                  this.mapDataSensor.push(data)
                }
              }
            }
            //展示workName
            let workName_temp1 = "";
            for(let i = 1;i < node.data.treeNodeDeep; i++ ){
              let workName_temp2 = node
              for(let j = i;j < node.data.treeNodeDeep; j++ ){
                //从上往下得到路径层级的名称
                workName_temp2 = workName_temp2.parent
              }
              workName_temp1 += workName_temp2.data.name + " >>> "
            }
            workName_temp1 += node.data.name
            console.log("flag=0 workName---:"+workName_temp1)

            this.workName = workName_temp1
            //这里注释了这行，可能造成的后果未知，待验证
            // this.subEquipmentIdByAtlas = node.data.subEquipmentId
            for (let m = 0; m < this.workData.length; m++) {
              if (node === this.workData[m].node) {
                this.accessData = this.workData[m].data
                this.tableData = this.accessData
                this.sensorData=this.accessData
                this.fileData=this.accessData;
                this.thirdPartyData=this.accessData
              }
            }
            for (let i = 0; i < this.workData.length; i++) {
              for (let j = 0; j < this.workData[i].data.length; j++) {
                this.workData[i].data[j].node.data.drag = 1
                this.$set(this.workData[i].data[j].node.data, 'icon', icon)
              }
            }
            for (let i = 0; i < this.workData.length; i++) {
              if (node === this.workData[i].node) {
                for (let j = 0; j < this.workData[i].data.length; j++) {
                  this.workData[i].data[j].node.data.drag = 0
                  this.$set(this.workData[i].data[j].node.data, 'icon', icon1)
                }
                break
              }
            }
            console.log("****wordData111")
            console.log("****wordData",this.workData)
            for (let i = 0; i < this.workData.length; i++) {
              if (this.workData[i].data.length === 0) {
                if (node !== this.workData[i].node) {
                  this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-xuanze2')
                  for(let s=0;s<this.taskData.length;s++){
                    //taskData[s]的结构是一个nowEachReflectData
                    // if(this.workData[i].node.data.subEquipmentId===this.taskData[s].subEquipmentId){
                    if(this.workData[i].node.data.levelId===this.taskData[s].levelId && this.workData[i].node.data.nodeId===this.taskData[s].nodeId){
                      this.taskData.splice(s,1);
                      s--
                      this.allTaskData.taskData=this.taskData
                    }
                  }

                  console.log("****wordData",this.workData)
                  let data={
                    workName:this.workData[i].workName
                  }
                  if (JSON.stringify(this.workDataList).indexOf(JSON.stringify(data)) > -1) {
                    this.workDataList.splice(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))-1, 1)
                  }
                  this.workData.splice(i,1)
                  i--
                }

              } else {
                this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze1')
                let data={
                  workName:this.workData[i].workName
                }
                if(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))===-1){
                  this.workDataList.push(data);
                }
              }
            }
            for (let i = 0; i < this.workData.length; i++) {
              if (node === this.workData[i].node) {
                console.log("bingo")
                this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze2')
                break
              }
            }
            break
          }


          //原来的代码
          // if (node.data.subEquipmentId === this.taskData[i].subEquipmentId) {//判断是否已被点过
          //   flag = 1
          //   this.nowEachTaskData = this.taskData[i]
          //   //切换显示的映射信息
          //   if (this.allTaskData.accessSource !== '') {
          //     if (JSON.stringify(this.nowEachTaskData.reflectData) !== "{}") {
          //
          //       for (let i = 0; i < this.nowEachTaskData.reflectData.DB.length; i++) {
          //         let DB = this.nowEachTaskData.reflectData.DB[i]
          //         for (let j = 0; j < DB.table.length; j++) {
          //           let table = DB.table[j]
          //           let data = {
          //             tableData: table.sourceField,
          //             deviceData: table.fieldName,
          //             tableName: table.tableName,
          //             fieldId: table.fieldId,
          //           }
          //           this.mapData.push(data)
          //           console.log(this.mapData)
          //         }
          //       }
          //       for (let i = 0; i < this.nowEachTaskData.reflectData.Sensor.length; i++) {
          //         let data = {
          //           cgqData: this.nowEachTaskData.reflectData.Sensor[i].cgqInfoSearch,
          //           deviceData: this.nowEachTaskData.reflectData.Sensor[i].fieldName,
          //         }
          //         this.mapDataSensor.push(data)
          //       }
          //     }
          //   }
          //   this.workName = node.parent.parent.parent.parent.data.name + " >>> " + node.parent.parent.parent.data.name + " >>> " + node.parent.parent.data.name + " >>> " + node.parent.data.name + " >>> " + node.data.name
          //   this.subEquipmentIdByAtlas = node.data.subEquipmentId
          //   for (let m = 0; m < this.workData.length; m++) {
          //     if (node === this.workData[m].node) {
          //       this.accessData = this.workData[m].data
          //       this.tableData = this.accessData
          //       this.sensorData=this.accessData
          //       this.fileData=this.accessData;
          //       this.thirdPartyData=this.accessData
          //     }
          //   }
          //   for (let i = 0; i < this.workData.length; i++) {
          //     for (let j = 0; j < this.workData[i].data.length; j++) {
          //       this.workData[i].data[j].node.data.drag = 1
          //       this.$set(this.workData[i].data[j].node.data, 'icon', icon)
          //     }
          //   }
          //   for (let i = 0; i < this.workData.length; i++) {
          //     if (node === this.workData[i].node) {
          //       for (let j = 0; j < this.workData[i].data.length; j++) {
          //         this.workData[i].data[j].node.data.drag = 0
          //         this.$set(this.workData[i].data[j].node.data, 'icon', icon1)
          //       }
          //       break
          //     }
          //   }
          //   console.log("****wordData111")
          //   console.log("****wordData",this.workData)
          //   for (let i = 0; i < this.workData.length; i++) {
          //     if (this.workData[i].data.length === 0) {
          //       if (node !== this.workData[i].node) {
          //         this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-xuanze2')
          //         for(let s=0;s<this.taskData.length;s++){
          //           if(this.workData[i].node.data.subEquipmentId===this.taskData[s].subEquipmentId){
          //             this.taskData.splice(s,1);
          //             s--
          //             this.allTaskData.taskData=this.taskData
          //           }
          //         }
          //
          //         console.log("****wordData",this.workData)
          //         let data={
          //           workName:this.workData[i].workName
          //         }
          //         if (JSON.stringify(this.workDataList).indexOf(JSON.stringify(data)) > -1) {
          //           this.workDataList.splice(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))-1, 1)
          //         }
          //         this.workData.splice(i,1)
          //         i--
          //       }
          //
          //     } else {
          //       this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze1')
          //       let data={
          //         workName:this.workData[i].workName
          //       }
          //       if(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))===-1){
          //         this.workDataList.push(data);
          //       }
          //     }
          //   }
          //   for (let i = 0; i < this.workData.length; i++) {
          //     if (node === this.workData[i].node) {
          //       console.log("bingo")
          //       this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze2')
          //       break
          //     }
          //   }
          //   break
          // }
        }
        // 如果当前任务对象为空，即工作区为空，初始化任务对象和当前工作区对象
        if (flag === 0) {
          // 初始化当前工作区，并将当前附属设备节点的值填充
          this.nowEachTaskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
          this.nowEachTaskData.levelId = node.data.levelId
          this.nowEachTaskData.nodeId = node.data.id
          // this.nowEachTaskData.subEquipmentId = node.data.subEquipmentId
          this.nowEachTaskData.name = node.data.name
          this.nowEachTaskData.id = this.workId

          // 将当前工作区对象加入到任务对象中
          this.taskData.push(this.nowEachTaskData)

          this.tableData = []
          this.fileData = []
          this.sensorData = []
          this.accessData = []
          this.thirdPartyData=[]

          //展示workName
          let workName_temp1 = "";
          for(let i = 1;i < node.data.treeNodeDeep; i++ ){
            let workName_temp2 = node
            for(let j = i;j < node.data.treeNodeDeep; j++ ){
              //从上往下得到路径层级的名称
              workName_temp2 = workName_temp2.parent
            }
            workName_temp1 += workName_temp2.data.name + " >>> "
          }
          workName_temp1 += node.data.name
          console.log("flag=0 workName---:"+workName_temp1)

          this.workName = workName_temp1
          // this.workName = node.parent.parent.parent.parent.data.name + " >>> " + node.parent.parent.parent.data.name + " >>> " + node.parent.parent.data.name + " >>> " + node.parent.data.name + " >>> " + node.data.name
          // this.subEquipmentIdByAtlas = node.data.subEquipmentId
          console.log("workData1: ", this.workData)
          // console.log("workData2: ", this.workData)

          let data = {
            node: node,
            data: [],
            // workName:node.parent.parent.parent.parent.data.name + " >>> " + node.parent.parent.parent.data.name + " >>> " + node.parent.parent.data.name + " >>> " + node.parent.data.name + " >>> " + node.data.name
            workName: this.workName
          }
          //把当前叶子节点图标设置为已选择 xuanze2：普通黑色  yixuanze1：蓝色  yixuanze2：绿色
          this.$set(node.data, 'icon', 'iconfont icon-yixuanze2')
          this.workData.push(data)
          console.log("workData3: ", this.workData)
          this.workId++
          //删除未映射的节点
          for (let i = 0; i < this.workData.length; i++) {
            if (this.workData[i].data.length === 0) {
              if (node !== this.workData[i].node) {
                this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-xuanze2')
                for (let s = 0; s < this.taskData.length; s++) {
                  if (this.workData[i].node.data.levelId === this.taskData[s].levelId && this.workData[i].node.data.nodeId === this.taskData[s].nodeId) {
                    this.taskData.splice(s, 1);
                    s--
                    this.allTaskData.taskData = this.taskData
                  }
                }
                console.log("****wordData", this.workData)
                let data={
                  workName:this.workData[i].workName
                }
                if (JSON.stringify(this.workDataList).indexOf(JSON.stringify(data)) > -1) {
                  this.workDataList.splice(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))-1, 1)
                }
                this.workData.splice(i, 1)
                i--
              }
            } else {
              this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze1')
              let data={
                workName:this.workData[i].workName
              }
              if(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))===-1){
                this.workDataList.push(data);
              }
            }
          }
          console.log("",this.workDataList)
          console.log(this.allTaskData.accessSource);
          //切换数据源图标的类型
          for (let i = 0; i < this.workData.length; i++) {
            for (let j = 0; j < this.workData[i].data.length; j++) {
              this.workData[i].data[j].node.data.drag = 1
              this.$set(this.workData[i].data[j].node.data, 'icon', icon)
            }
          }
        }
        this.workNode = node
        console.log(this.taskData, this.workData, this.mapData)



        // 如果当前任务对象为空，即工作区为空，初始化任务对象和当前工作区对象
        if (flag === 0) {
          // 初始化当前工作区，并将当前附属设备节点的值填充
          this.nowEachTaskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
          this.nowEachTaskData.subEquipmentId = node.data.subEquipmentId
          this.nowEachTaskData.name = node.data.name
          this.nowEachTaskData.id = this.workId

          // 将当前工作区对象加入到任务对象中
          this.taskData.push(this.nowEachTaskData)

          this.tableData = []
          this.fileData = []
          this.sensorData = []
          this.accessData = []
          this.thirdPartyData=[]
          this.workName = node.parent.parent.parent.parent.data.name + " >>> " + node.parent.parent.parent.data.name + " >>> " + node.parent.parent.data.name + " >>> " + node.parent.data.name + " >>> " + node.data.name
          this.subEquipmentIdByAtlas = node.data.subEquipmentId
          console.log("workData1: ", this.workData)

          console.log("workData2: ", this.workData)

          let data = {
            node: node,
            data: [],
            workName:node.parent.parent.parent.parent.data.name + " >>> " + node.parent.parent.parent.data.name + " >>> " + node.parent.parent.data.name + " >>> " + node.parent.data.name + " >>> " + node.data.name
          }
          //把当前叶子节点图标设置为已选择 xuanze2：普通黑色  yixuanze1：蓝色  yixuanze2：绿色
          this.$set(node.data, 'icon', 'iconfont icon-yixuanze2')
          this.workData.push(data)
          console.log("workData3: ", this.workData)
          this.workId++
          //这部分代码看不懂
          for (let i = 0; i < this.workData.length; i++) {
            if (this.workData[i].data.length === 0) {
              if (node !== this.workData[i].node) {
                this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-xuanze2')
                for (let s = 0; s < this.taskData.length; s++) {
                  if (this.workData[i].node.data.subEquipmentId === this.taskData[s].subEquipmentId) {
                    this.taskData.splice(s, 1);
                    s--
                    this.allTaskData.taskData = this.taskData
                  }
                }
                console.log("****wordData", this.workData)
                let data={
                  workName:this.workData[i].workName
                }
                if (JSON.stringify(this.workDataList).indexOf(JSON.stringify(data)) > -1) {
                  this.workDataList.splice(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))-1, 1)
                }
                this.workData.splice(i, 1)
                i--
              }
            } else {
              this.$set(this.workData[i].node.data, 'icon', 'iconfont icon-yixuanze1')
              let data={
                workName:this.workData[i].workName
              }
              if(JSON.stringify(this.workDataList).indexOf(JSON.stringify(data))===-1){
                this.workDataList.push(data);
              }
            }
          }
          console.log("",this.workDataList)
          console.log(this.allTaskData.accessSource);
          //切换数据源图标的类型
          for (let i = 0; i < this.workData.length; i++) {
            for (let j = 0; j < this.workData[i].data.length; j++) {
              this.workData[i].data[j].node.data.drag = 1
              this.$set(this.workData[i].data[j].node.data, 'icon', icon)
            }
          }
        }
        this.workNode = node
        console.log(this.taskData, this.workData, this.mapData)
      }
    },
    // 拖拽数据源节点到工作区
    dataSourceTreeDragEnd(node) {
      // 判断当前是否有工作区
      if (this.workName !== '无') {
        // 判断当前节点是否可拖拽，即是否是数据源对象节点，而非数据源层级节点
        //这个node是数据源的node，而不是左上角项目叶子节点的node
        if (this.dragFlg === 1 && node.data.drag === 1 && this.workName !== '无') {
          // 判断当前数据源节点的类型是否和工作区的类型一样，保证不同类型的数据源不能出现在同一个任务中
          if (this.allTaskData.accessSource === '' || node.data.type === this.allTaskData.accessSource) {
            this.allTaskData.accessSource = node.data.type

            let flag = 0
            for (let i = 0; i < this.taskData.length; i++) {
              //判断是否为叶子节点的条件，需要修改
              // if (this.workNode.data.subEquipmentId === this.taskData[i].subEquipmentId) {
              if (this.workNode.data.levelId === this.taskData[i].levelId && this.workNode.data.id === this.taskData[i].nodeId) {
                flag = 1
              }
            }
            if (flag === 0) {
              //each_taskData_temp仅仅作为一个结构，并无实际数据
              this.nowEachTaskData = JSON.parse(JSON.stringify(this.each_taskData_temp))
              this.nowEachTaskData.levelId = node.data.levelId
              this.nowEachTaskData.nodeId = node.data.id
              // this.nowEachTaskData.subEquipmentId = this.workNode.data.subEquipmentId
              this.nowEachTaskData.name = this.workNode.data.name
              this.nowEachTaskData.id = this.workId
              this.taskData.push(this.nowEachTaskData)
            }
            if (this.allTaskData.accessSource === 'mysql' || this.allTaskData.accessSource === 'sql server' || this.allTaskData.accessSource === 'oracle' || this.allTaskData.accessSource === 'influxDB') {
              this.tableData = this.accessData
              //这里的node是左下角数据库的node
              //linkId是数据库id，如 bigdata_dev 数据库
              this.allTaskData.linkId = node.parent.data.id
              //tableData是一个表的数据
              let tableData = {
                //这个subEquipmentId暂时不删除，但是它的值是空的
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.tableId,
                iconId: 'icon' + this.tableId,
                icon: "iconfont icon-biaodanmoshi2",
                data: true,
                node: node,
                delete: true,
                name: node.parent.data.name + "." + node.data.name,
                tableName: node.data.name,
                tableDB: node.parent.data.name
              }
              //s** edit
              //保存当前数据库，不能跨数据库做映射，到时需要做判断
              this.nowDB = node.parent.data.name
              // this.nowTable = node.data.name
              //添加到tableData中
              this.tableData.push(tableData)
              this.accessData = this.tableData
              console.log("accessData", this.accessData)
              // this.imageTableData.push(imageTableData)
              this.tableId = this.tableId + 1
              //数据源节点拖拽标志
              this.dragFlg = 0
              //数据表节点拖拽标志
              node.data.drag = 0
              this.$set(node.data, 'icon', 'iconfont icon-biaodanmoshi1')
            } else if (this.allTaskData.accessSource === 'txt' || this.allTaskData.accessSource === 'word' || this.allTaskData.accessSource === 'excel' || this.allTaskData.accessSource === 'image') {
              console.log("*********")
              this.fileData = this.accessData
              this.allTaskData.linkId = node.data.fileId
              let fileData = {
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.fileId,
                iconId: 'icon' + this.fileId,
                icon: '',
                data: true,
                node: node,
                delete: true,
                name: node.data.name,
                fileName: node.data.name,
                filesPath: node.data.filesPath,
              }
              if (this.allTaskData.accessSource === 'txt') {
                fileData.icon = "iconfont icon-txtwenjian2";
                this.$set(node.data, 'icon', 'iconfont icon-txtwenjian1')
              } else if (this.allTaskData.accessSource === 'word') {
                fileData.icon = "iconfont icon-Word2";
                this.$set(node.data, 'icon', 'iconfont icon-Word1')
              } else if (this.allTaskData.accessSource === 'excel') {
                fileData.icon = "iconfont icon-Excel2";
                this.$set(node.data, 'icon', 'iconfont icon-Excel1')
              } else if (this.allTaskData.accessSource === 'image') {
                fileData.icon = "iconfont icon-Excel2";
                this.$set(node.data, 'icon', 'iconfont icon-Excel1')
              }
              //添加到fileData中
              this.fileData.push(fileData)
              this.accessData = this.fileData
              console.log("accessData", this.accessData)
              this.fileId = this.fileId + 1
              this.dragFlg = 0
              node.data.drag = 0
            } else if (this.allTaskData.accessSource === '传感器') {
              this.allTaskData.linkId = node.data.sensorId
              let sensorData = {
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.sensorId,
                iconId: 'icon' + this.sensorId,
                icon: 'iconfont icon-icon-test2',
                data: true,
                node: node,
                delete: true,
                name: node.data.name,
                sensorName: node.data.name,
                info: node.data.info,
              }
              //添加到sensorData中
              this.sensorData.push(sensorData)
              this.accessData = this.sensorData
              this.sensorId = this.sensorId + 1
              this.dragFlg = 0
              node.data.drag = 0
              this.$set(node.data, 'icon', 'iconfont icon-icon-test1')
            } else if (this.allTaskData.accessSource === '第三方') {
              console.log(node)
              this.allTaskData.linkId = node.data.thirdPartyId
              let thirdPartyData = {
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.thirdPartyId,
                iconId: 'icon' + this.thirdPartyId,
                icon: 'iconfont icon-icon-test2',
                data: true,
                node: node,
                delete: true,
                dataList:node.data.dataList,
                name: node.data.name,
                thirdPartyName: node.data.name,
              }
              //thirdPartyData
              this.thirdPartyData.push(thirdPartyData)
              this.accessData = this.thirdPartyData
              this.thirdPartyId = this.thirdPartyId + 1
              this.dragFlg = 0
              node.data.drag = 0
              this.$set(node.data, 'icon', 'iconfont icon-icon-test1')
            } else if (this.allTaskData.accessSource === '表单') {
              this.allTaskData.linkId = node.data.formId
              let formData = {
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.formId,
                iconId: 'icon' + this.formId,
                icon: 'iconfont icon-icon-test2',
                data: true,
                node: node,
                delete: true,
                name: node.data.name,
                formField: node.data.field,
              }
              //thirdPartyData
              this.formData.push(formData)
              this.accessData = this.formData
              this.formId = this.formId + 1
              this.dragFlg = 0
              node.data.drag = 0
              this.$set(node.data, 'icon', 'iconfont icon-icon-test1')
            } else if (this.allTaskData.accessSource === 'opc') {
              console.log("node.data:"+JSON.stringify(node.data))
              this.allTaskData.linkId = node.parent.parent.data.opcId
              console.log("this.allTaskData.linkId:"+this.allTaskData.linkId)
              let opcData = {
                subEquipmentId: this.workNode.data.subEquipmentId,
                //levelId和nodeId用来替代subEquipmentId
                levelId: this.workNode.data.levelId,
                nodeId: this.workNode.data.id,
                id: this.opcId,
                iconId: 'icon' + this.opcId,
                icon: 'iconfont icon-icon-test2',
                data: true,
                node: node,
                delete: true,
                name: node.data.name,
                opcName: node.data.OPCName,
              }
              this.opcData.push(opcData)
              this.accessData = this.opcData
              this.opcId = this.opcId + 1
              this.dragFlg = 0
              node.data.drag = 0
              this.$set(node.data, 'icon', 'iconfont icon-icon-test1')
            }
            console.log("*********")
            for (let i = 0; i < this.workData.length; i++) {
              if (this.workNode === this.workData[i].node) {
                this.workData[i].data = this.accessData
              }
            }
          } else if (node.data.type !== this.allTaskData.accessSource) {
            this.$message({
              message: '拖拽的数据源类型不同！',
              type: 'warning'
            });
          }
        }
      } else {
        this.$message({
          message: '请先选择一个工作区！',
          type: 'warning'
        });
      }
    },
    //双击拖拽的数据表后触发
    workDataMouseDown(id, data) {

      if (this.allTaskData.accessSource === 'mysql' || this.allTaskData.accessSource === 'sql server' || this.allTaskData.accessSource === 'oracle' || this.allTaskData.accessSource === 'influxDB') {
        this.nowTableId = id
        this.selectData = []
        this.tableFieldData = [];

        this.deviceFieldData = [];
        this.postRequest("/universaltable/getOtherLeafItem?tableId="+this.workNode.data.levelId+"&id="+this.workNode.data.id).then(response => {
          console.log("/universaltable/getOtherLeafItem data:"+response.data)
          for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length; i++) {
            let data = {
              name: response.data[i].name,
              type: response.data[i].type,
              // fieldId: response.data[i].fieldId,
              fieldId: response.data[i].id,
              flag: '未选中',
              seen: false
            }
            if(data.type === undefined || data.type === ""){
              data.type = "未设定"
            }
            this.deviceFieldData.push(data)
          }
        }).then(error => {
        })

        //当前点击的table
        this.deviceName = this.workNode.data.name
        for (let i = 0; i < this.tableData.length; i++) {
          if (id === this.tableData[i].id) {
            this.tableName = this.tableData[i].tableName;
            let data = {
              dataBaseId: this.tableData[i].node.parent.data.id,
              tableName: this.tableData[i].tableName,
              tableDB: this.tableData[i].tableDB,
              tableDBType: this.allTaskData.accessSource,
              uid: this.currOperator,
            }
            this.postRequest("/addDataAccessTask/tableField", data).then(response => {
              for (let i = 0; i < JSON.parse(JSON.stringify(response.data)).length / 2; i++) {
                let data = {
                  name: response.data[i],
                  type: response.data[JSON.parse(JSON.stringify(response.data)).length / 2 + i],
                  tableName: this.tableName,
                  flag: '未选中'
                }
                this.tableFieldData.push(data)
              }
            }).then(error => {
              // console.log(error)
            })
            // this.tableFieldDataCopy = this.tableFieldData;

            //当前的映射
            this.nowReflectData = this.nowEachTaskData.reflectData
            console.log(this.nowReflectData)
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
              this.nowOneReflectDB = JSON.parse(JSON.stringify(this.oneReflectDB_temp))
              this.nowOneReflectDB.DBName = this.tableData[i].tableDB
              this.nowReflectData.DB.push(this.nowOneReflectDB)
            } else {
              let same = 0
              for (let m = 0; m < this.nowReflectData.DB.length; m++) {
                if (this.tableData[i].tableDB === this.nowReflectData.DB[m].DBName) {
                  this.nowOneReflectDB = JSON.parse(JSON.stringify(this.nowReflectData.DB[m]))
                  same = 1
                  break
                }
              }
              if (same === 0) {
                this.nowOneReflectDB = JSON.parse(JSON.stringify(this.oneReflectDB_temp))
                this.nowOneReflectDB.DBName = this.tableData[i].tableDB
                this.nowReflectData.DB.push(this.nowOneReflectDB)
              }
            }

            for (let m = 0; m < this.nowReflectData.DB.length; m++) {
              if (this.tableData[i].tableDB === this.nowReflectData.DB[m].DBName) {
                for (let n = 0; n < this.nowReflectData.DB[m].table.length; n++) {
                  if (this.tableData[i].tableName === this.nowReflectData.DB[m].table[n].tableName) {
                    let data = {
                      selectTableField: this.nowReflectData.DB[m].table[n].sourceField,
                      selectDeviceField: this.nowReflectData.DB[m].table[n].fieldName,
                      tableName: this.nowReflectData.DB[m].table[n].tableName,
                      fieldId: this.nowReflectData.DB[m].table[n].fieldId,
                    }
                    this.selectData.push(data)
                  }
                }
                break;
              }
            }
            break;
          }
        }
        setTimeout(() => {
          for (let i = 0; i < this.mapData.length; i++) {
            for (let j = 0; j < this.tableFieldData.length; j++) {
              if (this.mapData[i].tableData === this.tableFieldData[j].name && this.mapData[i].tableName === this.tableFieldData[j].tableName) {
                this.tableFieldData[j].flag = '已选中2'
              }
            }
          }
          for (let i = 0; i < this.mapData.length; i++) {
            for (let j = 0; j < this.deviceFieldData.length; j++) {
              if (this.mapData[i].deviceData === this.deviceFieldData[j].name) {
                this.deviceFieldData[j].flag = '已选中2'
              }
            }
          }
          for (let j = 0; j < this.tableFieldData.length; j++) {
            if (this.tableFieldData[j].flag === '已选中2') {
              this.$refs.selectTableField.toggleRowSelection(this.tableFieldData[j], true)
            }
            this.$options.methods.selectTableStatus(this.tableFieldData[j])
          }
          for (let j = 0; j < this.deviceFieldData.length; j++) {
            if (this.deviceFieldData[j].flag === '已选中2') {
              this.$refs.selectDeviceField1.toggleRowSelection(this.deviceFieldData[j], true)
            }
            this.$options.methods.selectDeviceStatus(this.deviceFieldData[j])
          }
        }, 1000)
        this.tableDialog = true
      } else if (this.allTaskData.accessSource === 'txt' || this.allTaskData.accessSource === 'excel' || this.allTaskData.accessSource === 'word' || this.allTaskData.accessSource === 'image') {
        this.nowFileId = id
        for (let i = 0; i < this.fileData.length; i++) {
          if (id === this.fileData[i].id) {
            console.log(this.nowEachTaskData)
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData=JSON.parse(JSON.stringify(this.reflectData_temp))
            }
          }
        }
        console.log(this.nowReflectData)
        this.$nextTick(() => {
          this.$refs.file.loadDeviceFieldData(id, data)
        });
      } else if (this.allTaskData.accessSource === 'txt' || this.allTaskData.accessSource === 'excel' || this.allTaskData.accessSource === 'word' || this.allTaskData.accessSource === 'image') {
        this.nowFileId = id
        for (let i = 0; i < this.fileData.length; i++) {
          if (id === this.fileData[i].id) {
            console.log(this.nowEachTaskData)
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData=JSON.parse(JSON.stringify(this.reflectData_temp))
            }
          }
        }
        console.log(this.nowReflectData)
        this.$nextTick(() => {
          this.$refs.file.loadDeviceFieldData(id, data)
        });
      }else if (this.allTaskData.accessSource === '传感器') {
        this.nowSensorId = id
        for (let i = 0; i < this.sensorData.length; i++) {
          if (id === this.sensorData[i].id) {
            console.log(this.nowEachTaskData)
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData=JSON.parse(JSON.stringify(this.reflectData_temp))
            }
          }
        }
        console.log(this.nowReflectData)
        this.$nextTick(() => {
          this.$refs.sensor.loadDeviceFieldData(id, data)
        });

        // let selectSensorData = []
        // this.$refs.sensor.loadDeviceFieldData(id, data)
        // for (let i = 0; i < this.taskData.length; i++) {
        //   if (this.taskData[i].subEquipmentId === this.workNode.data.subEquipmentId) {
        //     this.nowReflectData = this.nowEachTaskData.reflectData
        //     if (Object.keys(this.nowReflectData).length === 0) {
        //       this.nowReflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
        //       this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))
        //       this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
        //     }
        //     // this.nowOneReflectSensor=this.taskData[i].reflectData.Sensor
        //     for (let j = 0; j < this.taskData[i].reflectData.Sensor.length; j++) {
        //       let data = {
        //         selectSensor: this.taskData[i].reflectData.Sensor[j].sensor,
        //         selectDeviceField: this.taskData[i].reflectData.Sensor[j].fieldName
        //       }
        //       selectSensorData.push(data)
        //     }
        //   }
        // }
        // this.$refs.sensor.open(selectSensorData)
      } else if (this.allTaskData.accessSource === '第三方') {
        this.nowThirdPartyId = id
        for (let i = 0; i < this.thirdPartyData.length; i++) {
          if (id === this.thirdPartyData[i].id) {
            console.log(this.nowEachTaskData)
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData=JSON.parse(JSON.stringify(this.reflectData_temp))
            }
          }
        }
        console.log(this.nowReflectData)
        this.$nextTick(() => {
          this.$refs.thirdParty.loadDeviceFieldData(id, data)
        });
        // let selectThirdPartyData = []
        // this.$refs.thirdParty.loadDeviceFieldData(id, data)
        // for (let i = 0; i < this.taskData.length; i++) {
        //   if (this.taskData[i].subEquipmentId === this.workNode.data.subEquipmentId) {
        //     this.nowReflectData = this.nowEachTaskData.reflectData
        //     if (Object.keys(this.nowReflectData).length === 0) {
        //       this.nowReflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
        //       this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))
        //       this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
        //     }
        //     // this.nowOneReflectSensor=this.taskData[i].reflectData.Sensor
        //     for (let j = 0; j < this.taskData[i].reflectData.ThirdParty.length; j++) {
        //       let data = {
        //         selectThirdParty: this.taskData[i].reflectData.ThirdParty[j].thirdParty,
        //         selectDeviceField: this.taskData[i].reflectData.ThirdParty[j].fieldName
        //       }
        //       selectThirdPartyData.push(data)
        //     }
        //   }
        // }
        // this.$refs.thirdParty.open(selectThirdPartyData)
      } else if (this.allTaskData.accessSource === '表单') {
        let selectFormData = []
        this.$refs.selfForm.loadDeviceFieldData(id, data)
        for (let i = 0; i < this.taskData.length; i++) {
          if (this.taskData[i].subEquipmentId === this.workNode.data.subEquipmentId) {
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData = JSON.parse(JSON.stringify(this.reflectData_temp))
              this.nowEachTaskData.reflectData = JSON.parse(JSON.stringify(this.nowReflectData))
              this.taskData[i] = JSON.parse(JSON.stringify(this.nowEachTaskData))
            }
            // this.nowOneReflectSensor=this.taskData[i].reflectData.Sensor
            for (let j = 0; j < this.taskData[i].reflectData.Form.length; j++) {
              let data = {
                selectFormField: this.taskData[i].reflectData.Form[j].formField,
                selectDeviceField: this.taskData[i].reflectData.Form[j].fieldName
              }
              selectFormData.push(data)
            }
          }
        }
        this.$refs.selfForm.open(selectFormData)
      }
      else if (this.allTaskData.accessSource === 'opc') {
        this.nowOpcId = id
        for (let i = 0; i < this.opcData.length; i++) {
          if (id === this.opcData[i].id) {
            console.log(this.nowEachTaskData)
            this.nowReflectData = this.nowEachTaskData.reflectData
            if (Object.keys(this.nowReflectData).length === 0) {
              this.nowReflectData=JSON.parse(JSON.stringify(this.reflectData_temp))
            }
          }
        }
        console.log(this.nowReflectData)
        this.$nextTick(() => {
          this.$refs.opc.loadDeviceFieldData(id, data)
        });
      }
    },
  },


  mounted() {

  }
  ,
  destroyed() {

  }
  ,
  created() {
    /**
     * 初始化操作
     */
    //获取session中的user的id和name
    if(this.$route.query.update==1) {
      this.getRequest(
          "/dataAccess/select/?taskId=" + this.$route.query.taskId
      ).then((resp) => {
        if (resp.data) {
          for (let j = 0; j < resp.data.length; j++) {
            let table = resp.data[j]

            let data = {
              tableData: table.fileField,
              deviceData: table.fieldName,
              tableName: table.fileName,
              fieldId: table.fieldId,
            }
            this.mapData.push(data)
          }
          console.log(this.mapData)
        }
      });
    }

    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;

    this.sourceType = this.$route.query.sourceType
    if (this.sourceType == 1) {
      //树的初始内容，具体数据在后面的请求中填充
      this.dataSourceTreeMessage.push(
          {
            id: 1,
            icon: 'iconfont icon-shujuku',
            name: '数据库',
            children: [{
              id: 4,
              icon: 'iconfont icon-mysql',
              name: 'mysql',
            }, {
              id: 5,
              icon: 'iconfont icon-sqlserver',
              name: 'sql server',
            }, {
              id: 6,
              icon: 'iconfont icon-oracle',
              name: 'oracle',
            }, {
              id: 7,
              icon: 'iconfont icon-a-ziyuan42',
              name: 'influxDB',
            }],
          }
      )
      //数据库数据源包含mysql和sqlserver多种，下面是分别请求几种数据源并展示在左下角数据源区
      this.postRequest("/addDataAccessTask/selectMysqlDBTableByUid?uid=" + this.currOperator).then(response => {
        console.log("selectMysqlDBTableByUid:"+JSON.stringify(response.data))
        let i = 0
        for (let obj in response.data) {//创建数据源层children
          if (!this.dataSourceTreeMessage[0].children[0].children) {
            this.$set(this.dataSourceTreeMessage[0].children[0], 'children', []);
          }
          //第一个数据不是数据表，而是数据源id {"id":id}，如第一个为opctest
          let DBName = {
            id: response.data[obj][0],
            name: obj,
            dbType: 'mysql'
          }
          //这个地方不懂为什么要做这步
          for (let key in response.data[obj][0]) {
            DBName.id = key
          }
          //把DBName放入第一个数据源中（如opctest）
          this.dataSourceTreeMessage[0].children[0].children.push(DBName);
          this.$set(this.dataSourceTreeMessage[0].children[0].children[i], 'icon', 'iconfont icon-shujuku');
          for (let j = 1; j < response.data[obj].length; j++) {//创建表格层children 注意j从1开始，跳过第零个为数据源id
            if (!this.dataSourceTreeMessage[0].children[0].children[i].children) {
              this.$set(this.dataSourceTreeMessage[0].children[0].children[i], 'children', []);
            }
            //保存数据库表的信息，格式和数据源一样
            let TableName = {
              name: response.data[obj][j],
              type: 'mysql',
              tableId: 0,
            }
            //这个地方不懂，response.data[obj][j]里应该只有一个键值对，为什么要用循环
            for (let key in response.data[obj][j]) {
              TableName.name = key
              TableName.tableId = response.data[obj][j][key]
            }
            //往第三层级的节点放入值
            this.dataSourceTreeMessage[0].children[0].children[i].children.push(TableName);
            this.$set(this.dataSourceTreeMessage[0].children[0].children[i].children[j - 1], 'drag', 1);
            this.$set(this.dataSourceTreeMessage[0].children[0].children[i].children[j - 1], 'icon', 'iconfont icon-biaodanmoshi');
          }
          i++
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {

        // console.log(error)
      });
      //下面逻辑和上面mysql的逻辑一致
      this.postRequest("/addDataAccessTask/selectSqlServerDBTableByUid?uid=" + this.currOperator).then(response => {
        let i = 0
        for (let obj in response.data) {
          if (!this.dataSourceTreeMessage[0].children[1].children) {
            this.$set(this.dataSourceTreeMessage[0].children[1], 'children', []);
          }
          let DBName = {
            id: response.data[obj][0],
            name: obj,
            dbType: 'sql server'
          }
          for (let key in response.data[obj][0]) {
            DBName.id = key
          }
          this.dataSourceTreeMessage[0].children[1].children.push(DBName);
          this.$set(this.dataSourceTreeMessage[0].children[1].children[i], 'icon', 'iconfont icon-shujuku');
          for (let j = 1; j < response.data[obj].length; j++) {
            if (!this.dataSourceTreeMessage[0].children[1].children[i].children) {
              this.$set(this.dataSourceTreeMessage[0].children[1].children[i], 'children', []);
            }
            let TableName = {
              name: response.data[obj][j],
              type: 'sql server',
              tableId: 0,
            }
            for (let key in response.data[obj][j]) {
              TableName.name = key
              TableName.tableId = response.data[obj][j][key]
            }
            this.dataSourceTreeMessage[0].children[1].children[i].children.push(TableName);
            this.$set(this.dataSourceTreeMessage[0].children[1].children[i].children[j - 1], 'drag', 1);
            this.$set(this.dataSourceTreeMessage[0].children[1].children[i].children[j - 1], 'icon', 'iconfont icon-biaodanmoshi');
          }
          i++
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
      this.postRequest("/addDataAccessTask/selectOracleDBTableByUid?uid=" + this.currOperator).then(response => {
        let i = 0
        for (let obj in response.data) {
          if (!this.dataSourceTreeMessage[0].children[2].children) {
            this.$set(this.dataSourceTreeMessage[0].children[2], 'children', []);
          }

          let DBName = {
            id: response.data[obj][0],
            name: obj,
            dbType: 'oracle'
          }
          for (let key in response.data[obj][0]) {
            DBName.id = key
          }
          this.dataSourceTreeMessage[0].children[2].children.push(DBName);
          this.$set(this.dataSourceTreeMessage[0].children[2].children[i], 'icon', 'iconfont icon-shujuku');
          for (let j = 1; j < response.data[obj].length; j++) {
            if (!this.dataSourceTreeMessage[0].children[2].children[i].children) {
              this.$set(this.dataSourceTreeMessage[0].children[2].children[i], 'children', []);
            }
            let TableName = {
              name: response.data[obj][j],
              type: 'oracle',
              tableId: 0,
            }
            for (let key in response.data[obj][j]) {
              TableName.name = key
              TableName.tableId = response.data[obj][j][key]
            }
            this.dataSourceTreeMessage[0].children[2].children[i].children.push(TableName);
            this.$set(this.dataSourceTreeMessage[0].children[2].children[i].children[j-1], 'drag', 1);
            this.$set(this.dataSourceTreeMessage[0].children[2].children[i].children[j-1], 'icon', 'iconfont icon-biaodanmoshi');
          }
          i++
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
      this.postRequest("/addDataAccessTask/selectInfluxDBTable?uid=" + this.currOperator).then(response => {
        let i = 0
        console.log("response.data",response.data)
        for (let obj in response.data) {
          if (!this.dataSourceTreeMessage[0].children[3].children) {
            this.$set(this.dataSourceTreeMessage[0].children[3], 'children', []);
          }
          let DBName = {
            id: response.data[obj][0],
            name: obj,
            dbType: 'influxDB'
          }
          for (let key in response.data[obj][0]) {
            DBName.id = key
          }
          this.dataSourceTreeMessage[0].children[3].children.push(DBName);
          this.$set(this.dataSourceTreeMessage[0].children[3].children[i], 'icon', 'iconfont icon-shujuku');
          for (let j = 1; j < response.data[obj].length; j++) {
            if (!this.dataSourceTreeMessage[0].children[3].children[i].children) {
              this.$set(this.dataSourceTreeMessage[0].children[3].children[i], 'children', []);
            }
            let TableName = {
              name: response.data[obj][j],
              type: 'influxDB'
            }
            for (let key in response.data[obj][j]) {
              TableName.name = key
              TableName.tableId = response.data[obj][j][key]
            }
            this.dataSourceTreeMessage[0].children[3].children[i].children.push(TableName);
            this.$set(this.dataSourceTreeMessage[0].children[3].children[i].children[j-1], 'drag', 1);
            this.$set(this.dataSourceTreeMessage[0].children[3].children[i].children[j-1], 'icon', 'iconfont icon-biaodanmoshi');
          }
          i++
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
    }
    if (this.sourceType == 2) {
      this.dataSourceTreeMessage.push(
          {
            id: 2,
            name: '文件',
            icon: 'iconfont icon-wenjianjia',
            children: [{
              id: 8,
              icon: 'iconfont icon-txtwenjian',
              name: 'txt',
            }, {
              id: 9,
              icon: 'iconfont icon-Word',
              name: 'word',
            }, {
              id: 10,
              icon: 'iconfont icon-Excel',
              name: 'excel',
            }, {
              id: 11,
              icon: 'iconfont icon-Excel',
              name: '图片',
            }
            ],
          }
      )
      console.log(this.dataSourceTreeMessage)
      this.postRequest("/addDataAccessTask/selectTxt?uid=" + this.currOperator).then(response => {
        console.log("wenjian response data:"+JSON.stringify(response.data))
        for (let i = 0; i < response.data.length; i++) {
          let fileName = {
            name: response.data[i].name,
            drag: 1,
            type: 'txt',
            icon: 'iconfont icon-txtwenjian',
            filesPath: response.data[i].localPath,
            fileId: response.data[i].id
          }
          if (!this.dataSourceTreeMessage[0].children[0].children) {
            this.$set(this.dataSourceTreeMessage[0].children[0], 'children', []);
          }
          this.dataSourceTreeMessage[0].children[0].children.push(fileName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
      console.log(this.dataSourceTreeMessage)

      this.postRequest("/addDataAccessTask/selectWord?uid=" + this.currOperator).then(response => {
        for (let i = 0; i < response.data.length; i++) {
          let fileName = {
            name: response.data[i].name,
            drag: 1,
            type: 'word',
            icon: 'iconfont icon-txtwenjian',
            filesPath: response.data[i].localPath,
            fileId: response.data[i].id
          }
          if (!this.dataSourceTreeMessage[0].children[1].children) {
            this.$set(this.dataSourceTreeMessage[0].children[1], 'children', []);
          }
          this.dataSourceTreeMessage[0].children[1].children.push(fileName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });

      this.postRequest("/addDataAccessTask/selectExcel?uid=" + this.currOperator).then(response => {
        for (let i = 0; i < response.data.length; i++) {
          let fileName = {
            name: response.data[i].name,
            drag: 1,
            type: 'excel',
            icon: 'iconfont icon-txtwenjian',
            filesPath: response.data[i].localPath,
            fileId: response.data[i].id
          }
          if (!this.dataSourceTreeMessage[0].children[2].children) {
            this.$set(this.dataSourceTreeMessage[0].children[2], 'children', []);
          }
          this.dataSourceTreeMessage[0].children[2].children.push(fileName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });

      this.getRequest("/addDataAccessTask/selectImage?uid=" + this.currOperator).then(response => {
        for (let i = 0; i < response.data.length; i++) {
          let fileName = {
            name: response.data[i].name,
            drag: 1,
            type: 'image',
            icon: 'iconfont icon-txtwenjian',
            filesPath: response.data[i].localPath,
            fileId: response.data[i].id
          }
          if (!this.dataSourceTreeMessage[0].children[3].children) {
            this.$set(this.dataSourceTreeMessage[0].children[3], 'children', []);
          }
          this.dataSourceTreeMessage[0].children[3].children.push(fileName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
    }
    if (this.sourceType == 3) {
      this.dataSourceTreeMessage.push(
          {
            id: 3,
            name: '传感器',
            icon: 'iconfont icon-icon-test',
          }
      )
      this.postRequest("/addDataAccessTask/selectSensor?uid=" + this.currOperator).then(response => {
        for (let i = 0; i < response.data.length; i++) {
          if (!this.dataSourceTreeMessage[0].children) {
            this.$set(this.dataSourceTreeMessage[0], 'children', []);
          }
          let sensorName = {
            name: response.data[i].name,
            info: response.data[i].info,
            drag: 1,
            icon: 'iconfont icon-icon-test',
            type: '传感器',
            sensorId: response.data[i].id,
          }
          this.dataSourceTreeMessage[0].children.push(sensorName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
    }
    if (this.sourceType == 4) {
      this.dataSourceTreeMessage.push(
          {
            id: 11,
            name: '第三方',
            icon: 'iconfont icon-icon-test',
          }
      )
      this.postRequest("/addDataAccessTask/selectThirdParty?uid=" + this.currOperator).then(response => {
        for (let i = 0; i < response.data.length; i++) {
          if (!this.dataSourceTreeMessage[0].children) {
            this.$set(this.dataSourceTreeMessage[0], 'children', []);
          }
          let thirdPartyName = {
            name: response.data[i].name,
            drag: 1,
            icon: 'iconfont icon-icon-test',
            type: '第三方',
            dataList:response.data[i].dataList,
            thirdPartyId: response.data[i].id,
          }
          console.log(thirdPartyName)
          this.dataSourceTreeMessage[0].children.push(thirdPartyName);
        }
        this.dataSourceTree = false;
        this.$nextTick(() => (this.dataSourceTree = true))
      }).then(error => {
        // console.log(error)
      });
    }


    if (this.sourceType == 5) {
      this.dataSourceTreeMessage.push(
          {
            id: 12,
            name: 'opc',
            icon: 'iconfont icon-icon-test',
          }
      )
      this.postRequest("/addDataAccessTask/selectOpc?uid=" + this.currOperator).then(response => {
        console.log("selectOpc:"+JSON.stringify(response.data))
        //dataSourceTreeMessage[0]就是opc，只有一个根层级
        for (let i = 0; i < response.data.length; i++) {
          if (!this.dataSourceTreeMessage[0].children) {
            this.$set(this.dataSourceTreeMessage[0], 'children', []);
          }
          let OPCName = {
            name: response.data[i].alias,
            drag: 1,
            icon: 'iconfont icon-icon-test',
            type: 'opc',
            opcId: response.data[i].id,
            ip:response.data[i].ip,
            port:response.data[i].port,
            username:response.data[i].username,
            password:response.data[i].password,
            uid:this.currOperator,
          }
          this.dataSourceTreeMessage[0].children.push(OPCName);
          this.postRequest("/addDataAccessTask/getOpcDevice",OPCName).then(response => {

            let k=0;
            for (let obj in response.data) {
              console.log("response.data**",response.data[obj].length)
              if (!this.dataSourceTreeMessage[0].children[i].children) {
                this.$set(this.dataSourceTreeMessage[0].children[i], 'children', []);
              }
              let passagewayName = {
                name: obj,
                icon: 'iconfont icon-test',
              }
              this.dataSourceTreeMessage[0].children[i].children.push(passagewayName);
              for (let j = 0; j < response.data[obj].length; j++) {
                console.log("response.obj",response.data[obj][j])
                if (!this.dataSourceTreeMessage[0].children[i].children[k].children) {
                  this.$set(this.dataSourceTreeMessage[0].children[i].children[k], 'children', []);
                }
                let DeviceName = {
                  name: response.data[obj][j],
                  type: 'opc',
                  OPCName: OPCName.name,
                  drag: 1,
                  icon: 'iconfont icon-icon-test',
                  opcId: OPCName.id,
                  ip:OPCName.ip,
                  port:OPCName.port,
                  username:OPCName.username,
                  password:OPCName.password,
                  uid:OPCName.uid,
                }
                console.log("response.data**",DeviceName.OPCName)
                this.dataSourceTreeMessage[0].children[i].children[k].children.push(DeviceName);
              }
              k++;
            }
            this.dataSourceTree = false;
            this.$nextTick(() => (this.dataSourceTree = true))
          }).then(error => {
            // console.log(error)
          });
        }
      }).then(error => {
        // console.log(error)
      });

    }

    //这部分代码不知道具体在做什么？
    //新任务或者已存在的任务需要修改？
    //刷新时方式前面页面传过来的参数
    this.routeParamsUpdateFlag = this.$route.query.flag
    if (this.routeParamsUpdateFlag === 0||this.routeParamsUpdateFlag===undefined) {
      //taskName是任务名称
      this.allTaskData.taskName = this.$route.query.taskName
      this.allTaskData.remark = this.$route.query.remark
      this.allTaskData.type = this.$route.query.type
      this.allTaskData.currOperator = this.currOperator
      console.log(" this.allTaskData.taskName"+ this.allTaskData.taskName)
    } else {
      this.allTaskData.taskName = this.$route.query.taskName
      this.allTaskData.remark = this.$route.query.remark
      this.taskId = this.$route.query.taskId
      this.allTaskData.accessSource = this.$route.query.accessSource
      this.allTaskData.currOperator = this.currOperator
      this.allTaskData.flag = 1
      this.allTaskData.taskId = this.$route.query.taskId
      this.postRequest("/addDataAccessTask/selectMap?taskId=" + this.taskId).then(response => {
        this.updateData = response.data
        console.log("this.updateData", this.updateData)
        this.postRequest("/addDataAccessTask/selectWorkList?taskId=" + this.taskId).then(response => {
          this.workDataList = response.data
        }).then(error => {
          // console.log(error)
        });
      }).then(error => {
        // console.log(error)
      });
    }

  }
  ,
}
</script>

<style type="text/css" scoped>

                       .div1 {
                         display: block;
                         height: 1px;
                         width: 100%;
                         position: relative;
                       }
.div2 {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: #dcdfe6;
}
.title_score{
  margin-top: 30px;
  margin-left: 180px;
}
.body {
  min-width: 100%;
  height: 800px;
  position: relative;
  margin: 1px 25px 0px 0px;
  top: 15px;
  left:0;
  right:0;
  display: flex;
}
.allDiv{
  width: 100%;
  height: 100%;
  display: flex;
  border-left: 0.2px solid #000;
}
.leftDiv{
  width: 20%;
  height: 100%;
  float: left;
  border-bottom:0.2px solid #000;
  border-top:0.2px solid #000;
}
.leftDivTree{
  width: 100%;
  height: 93.7%;
  overflow: scroll;
}
.midDiv{
  width: 50%;
  height: 100%;
  float: left;
  border-top:0.2px solid #000;
  border-bottom:0.2px solid #000;
  border-left:0.2px solid #000;
  overflow-y: scroll;
}
.midDivTop{
  height:6%;
  width: 100%;
}
.midDivTopImg{
  padding-top: 5px;
  /*height: 31px;*/
}
.midDivData{
  width: 100%;
  height: 93.7%;
  margin-top: -10px;
  background: url("../../../../assets/画布背景.png");
}
.dy_datasourceDiv {
  width: 100%;
  height: 60%;
}
.rightDiv{
  height: 100%;
  flex: 1;
  border:0.5px solid #000;
}

.el-tree > .el-tree-node {
  display: inline-block;
  min-width: 100%;
}


.el-divider.el-divider--horizontal {
  margin: 0px 0px;
}

.menuDiv {
  margin-left: 10px;
  width: 100%;
  height: 4%;
  border: none;
}

.importDiv {
  margin-left: 10px;
  width: 100%;
  height: 95%;
}

.importLeftDiv {
  width: 53%;
  height: 100%;
  float: left;
}

.importRightDiv {
  margin-left: 10px;
  width: 45%;
  height: 100%;
  float: left;
  overflow: auto;
}

.datasourceDiv {
  width: 100%;
  height: 94%;
  background: url("../../../../assets/画布背景.png");
}

.el-form-item {
  margin-bottom: 0;
}

.el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}

.el-select {
  width: 100%;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.3rem;
  padding-right: 8px;
}

.tableDivider {
  margin-top: 5px;
  margin-bottom: 5px;
}

.el-form-item__content {
  line-height: 4px;
  position: relative;
  font-size: 14px;
}

.el-switch__label * {
  font-size: 16px;
}

.el-menu {
  border: 2px solid;
  border-color: rgb(220, 220, 220) rgb(128, 128, 128) rgb(128, 128, 128) rgb(220, 220, 220);
}

.el-menu-item {
  margin-bottom: 0px;
  font-size: 5px;
}

.el-menu-item, .el-submenu__title {
  height: 30px;
  line-height: 30px;
}

.s-bg2 {
  backgound: #eee;
}

.s-c {
  color: green;
}

::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '操作';
  text-align: center;
  line-height: 37px;
}
</style>